import Head from '@/components/Head';
import Header from '@/components/Landing/Header';
import Contact from '@/components/Landing/Contact';
import Partners from '@/components/Landing/Partners';
import Benefits from '@/components/Landing/Benefits';
import Connect from '@/components/Landing/Connect';
import Products from '@/components/Landing/Products';
import Main from '@/components/Landing/Main';
import FAQ from '@/components/Landing/FAQ';
import Footer from '@/components/Landing/Footer';

import style from './style.module.scss';

const Landing = () => (
  <>
    <Head />
    <div className={style.landingWrapper}>
      <Header />
      <Main />
      <Products />
      <Benefits />
      <Connect />
      <Partners />
      <Contact />
      <FAQ />
      <Footer />
    </div>
  </>
);

export default Landing;
