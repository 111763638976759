import style from './style.module.scss';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const LOCALE_TO_BROKER_BLOCK = {
  ru: (
    <>
      <div className={style.pic}>
        <video preload={'metadata'} autoPlay loop muted playsInline>
          <source
            src="https://1603042024.rsc.cdn77.org/coming-soon/video/lightning.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className={style.elTitle}>
        Официальный
        <br />
        брокер ведущих бирж
      </div>
      <div className={style.elSubtitle}>
        Все преимущества работы <br />с популярными криптобиржами
      </div>
    </>
  ),
  en: (
    <>
      <div className={style.pic}>
        <video preload={'metadata'} autoPlay loop muted playsInline>
          <source
            src="https://1603042024.rsc.cdn77.org/coming-soon/video/lightning.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className={style.elTitle}>
        Broker on the best
        <br />
        crypto exchanges
      </div>
      <div className={style.elSubtitle}>
        Benefit from assets trading through secure infrastructure
      </div>
    </>
  ),

  ua: (
    <>
      <div className={style.pic}>
        <video preload={'metadata'} autoPlay loop muted playsInline>
          <source
            src="https://1603042024.rsc.cdn77.org/coming-soon/video/lightning.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className={style.elTitle}>
        Брокер найкращих
        <br />
        криптовалютних бірж
      </div>
      <div className={style.elSubtitle}>Відчуй переваги торгівлі з надійною інфраструктурою</div>
    </>
  ),
};

const Benefits = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  return (
    <div className={style.main}>
      <div className={style.benefits}>
        <div className={style.mainContainer}>
          <div className={style.title}>{t('slide3_h1')}</div>
          <div className={style.number} />
          <div className={style.subTitle}>{t('slide3_h3')}</div>
          <div className={style.grid}>
            <div className={style.el}>
              <div className={style.pic}>
                <video preload={'metadata'} autoPlay loop muted playsInline>
                  <source src="/video/Switz.mp4" type="video/mp4" />
                </video>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: t('slide3_infoblock1_h1') }}
                className={style.elTitle}
              />
              <div className={style.elSubtitle}>{t('slide3_infoblock1_h2')}</div>
            </div>
            <div className={style.el}>{LOCALE_TO_BROKER_BLOCK[locale]}</div>
            <div className={style.el}>
              <div className={style.pic}>
                <video preload={'metadata'} autoPlay loop muted playsInline>
                  <source src="/video/energy.mp4" type="video/mp4" />
                </video>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: t('slide3_infoblock3_h1') }}
                className={style.elTitle}
              />
              <div className={style.elSubtitle}>{t('slide3_infoblock3_h2')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
