import { useTranslation } from 'next-i18next';
import getConfig from 'next/config';
import Script from 'next/dist/client/script';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { memo, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { HOST_NAME } from '../../constant';
import { cutPageName, useTranslationByMetaTag } from '../../helper';

const {
  publicRuntimeConfig: { gtmKey },
} = getConfig();

const HeadComponent = () => {
  const {
    asPath,
    query: { page },
    locales,
    locale,
  } = useRouter();

  const { t } = useTranslation();

  useEffect(() => {
    TagManager.initialize({ gtmId: `GTM-${gtmKey}` });
  }, []);

  const titleForHowTo = () => {
    const translationByMetaTag = useTranslationByMetaTag('title');

    // FIXME: get information about what `connections_`is and refactor (use useTranslationByMetaTag only)
    if (page) {
      const pageKey = cutPageName(asPath);
      const titleKey = `title_${pageKey}`;
      const titleTranslation = t(titleKey);
      const connectionKey = `connections_title_${page}`;
      const connectionTranslation = t(connectionKey);

      if (pageKey && titleTranslation !== titleKey) {
        if (connectionTranslation !== connectionKey) {
          return `${titleTranslation} ${connectionTranslation}`;
        }
      }
    }

    return translationByMetaTag;
  };

  const getCanonicalLink = (alternate, alternateLocale = '') => {
    let linkLocale;
    let supportLinkLocale;
    if (alternate) {
      linkLocale = locale === 'ru' ? '' : '/ru';
      supportLinkLocale = locale === 'ru' ? 'en' : 'ru-RU';
    } else {
      linkLocale = locale ? `/${locale}` : '';
      supportLinkLocale = locale === 'ru' ? 'ru-RU' : 'en';
    }
    const path = asPath.split('?')[0].split('&')[0];

    if (alternateLocale) {
      linkLocale = `${alternateLocale === 'en' ? '' : `/${alternateLocale}`}`;
    }

    switch (page) {
      case 'binance':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825576-binance`;
      case 'bitmex':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825574-bitmex`;
      case 'bitfinex':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825575-bitfinex`;
      case 'bybit':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825582-bybit`;
      case 'deribit':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825592-deribit`;
      case 'hitbtc':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825593-hitbtc`;
      case 'quik':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825554-quik`;
      case 'transaq':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825557-transaq`;
      case 'metatrader':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825559-metatrader-5`;
      case 'smartcom':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825555-smartcom`;
      case 'rithmic':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825566-rithmic`;
      case 'gaincapital':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825569-gain-capital-oec`;
      case 'interactivebrokers':
        return `https://support.tiger.trade/${supportLinkLocale}/support/solutions/articles/80000825570-interactive-brokers`;
      default:
        return `https://www.tiger.trade${linkLocale}${path === '/' ? '' : path}`;
    }
  };

  return (
    <Head>
      <title>{titleForHowTo()}</title>
      <link rel="shortcut icon" href="/images/favicon.ico" />
      <link rel="canonical" href={getCanonicalLink(false)} />
      {locales &&
        locales
          .filter((i) => i !== locale)
          .map((i) => (
            <link key={i} rel="alternate" hrefLang={i} href={getCanonicalLink(true, i)} />
          ))}
      <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=yes" />
      <meta name="description" content={useTranslationByMetaTag('description')} />
      <meta property="og:title" content={useTranslationByMetaTag('og_title')} />
      <meta property="og:description" content={useTranslationByMetaTag('og_description')} />
      <meta property="og:image" content={`${HOST_NAME}/img/preview.png`} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:secure_url" content={`${HOST_NAME}/img/preview.png`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="600" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.tiger.trade/" />
      <meta name="image" content={`${HOST_NAME}/img/preview.png`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:src" content={`${HOST_NAME}/img/preview.png`} />
      <Script
        src="https://widget.cloudpayments.ru/bundles/cloudpayments.js"
        strategy="beforeInteractive"
      />
      {asPath === '/' && (
        <>
          <meta name="yandex-verification" content="9d859715938020ba" />
          <meta name="facebook-domain-verification" content="654r0ovc20l06fp36z01fm9r7du93n" />
          <meta
            name="google-site-verification"
            content="RGSH9TcEaahQzKSe23ECsGm4ACmr0w4p-j4X1X528iY"
          />
        </>
      )}
    </Head>
  );
};

export default memo(HeadComponent);
