import style from './style.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const Connect = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  return (
    <div className={style.main}>
      <div className={style.connect}>
        <div className={style.mainContainer}>
          <div className={style.title}>
            {t('slide4_h1')}
            <br />
            <span className={style.grad}>{t('slide4_h1_1')}</span>
          </div>
        </div>
        <div className={style.wrap}>
          <div className={clsx(style.line, style.line1)} />
          <div
            className={clsx(style.line, {
              [style.line2en]: locale === 'en',
              [style.line2ru]: locale !== 'en',
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default Connect;
