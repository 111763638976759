export const Icon1 = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="8" fill="url(#paint0_linear_15677_39406)" />
      <mask id="mask0_15677_39406" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" rx="8" fill="#FF8933" />
      </mask>
      <g mask="url(#mask0_15677_39406)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1 40C7.28427 40 14 33.2843 14 25C14 16.7157 7.28427 10 -1 10C-9.28427 10 -16 16.7157 -16 25C-16 33.2843 -9.28427 40 -1 40ZM-1 35C4.52285 35 9 30.5228 9 25C9 19.4772 4.52285 15 -1 15C-6.52285 15 -11 19.4772 -11 25C-11 30.5228 -6.52285 35 -1 35Z"
          fill="#0F0D19"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 18C40.1503 18 50 8.15026 50 -4C50 -16.1503 40.1503 -26 28 -26C15.8497 -26 6 -16.1503 6 -4C6 8.15026 15.8497 18 28 18ZM28 13.3333C37.5729 13.3333 45.3333 5.5729 45.3333 -4.00003C45.3333 -13.573 37.5729 -21.3334 28 -21.3334C18.427 -21.3334 10.6666 -13.573 10.6666 -4.00003C10.6666 5.5729 18.427 13.3333 28 13.3333Z"
          fill="#0F0D19"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_15677_39406"
          x1="12"
          y1="0"
          x2="12"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8933" />
          <stop offset="1" stopColor="#FF5A33" />
        </linearGradient>
      </defs>
    </svg>
  );
};
