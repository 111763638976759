import { TERMINAL_MAC_OS_LINKS } from '@/core/constants';
import clsx from 'clsx';
import { TFunction, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import style from './style.module.scss';

const DROPDOWN_LIST_DATA_EN = (t: TFunction) => [
  {
    id: 1,
    title: 'What is tiger.com?',
    text: (
      <>
        Since 2015, tiger.com has been developing fintech products for traders of all levels: from
        experienced high-volume professionals to regular users. Our solutions are developed by an
        international team of fintech enthusiasts. The company is headquartered in Switzerland.
        <ul className={style.topOffset}>
          Our products are:
          <li>
            <a href="https://www.tiger.trade/terminal" className={style.link}>
              Tiger.Trade for Windows
            </a>
            &nbsp; — a desktop platform that enables users to trade on financial and cryptocurrency
            markets.
          </li>
          <li>
            <a href={TERMINAL_MAC_OS_LINKS.en} className={style.link}>
              Tiger.Trade macOS
            </a>{' '}
            — our desktop platform for macOS
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    title: 'What is trading platform?',
    text: (
      <>
        Tiger.Trade trading platform is a customizable desktop workspace for trading stocks,
        futures, cryptocurrencies, and Forex assets. The platform offers an exhaustive range of
        tools to satisfy the needs of beginners, advanced traders, and professionals alike.
        Tiger.Trade platform features:
        <ul className={style.topOffset}>
          <li>stock, futures, options, cryptocurrency, and Forex asset trading</li>
          <li>demo trading on live and historical data</li>
          <li>built-in risk management system</li>
          <li>
            multifunctional charts, including clusters, Renko and volume bars, including various
            indicators
          </li>
          <li>DOM with extensive features for active trading.</li>
        </ul>
        <div>Using the platform, users can connect to:</div>
        <ul className={style.topOffset}>
          <li>
            different cryptocurrency and stock exchanges, including NYSE, NASDAQ, MOEX, SPBEX,
            Binance, OKX, Huobi, and ByBit,
          </li>
          <li>
            trading terminals: SMARTcom, QUIK, MetaTrader 5, Rithmic, TRANSAQ, and GAIN Capital
            (OEC).
          </li>
        </ul>
        Tiger.Trade is currently available for&nbsp;
        <a href="https://www.tiger.trade/terminal" className={style.link}>
          Microsoft Windows.
        </a>
        &nbsp;and&nbsp;
        <a href={TERMINAL_MAC_OS_LINKS.en} className={style.link}>
          macOS
        </a>
        .
      </>
    ),
  },
  /*
  {
    id: 3,
    title: 'What is Tiger.Trade Copy?',
    text: (
      <>
        <a href="https://copy.tiger.trade/index-en.html" className={style.link}>
          Tiger.Trade Copy
        </a>
        &nbsp; is a copytrading mobile app that allows users copy trades of experienced traders and
        earn from their crypto trades. Users simply create an account, connect and fund their
        Wallet. Once a user subscribes to a trader, the app will automatically copy every trade they
        make.&nbsp;
        <mark>Tiger.Trade Copy</mark> is available for&nbsp;
        <a
          href="https://apps.apple.com/us/app/tiger-trade-binance-copytrade/id1604212247"
          target="_blank"
          className={style.link}
        >
          iOS
        </a>
        &nbsp; and&nbsp;
        <a
          href="https://play.google.com/store/apps/details?id=trade.tigr.copytrading"
          target="_blank"
          className={style.link}
        >
          Android.
        </a>
        <div className={style.topOffset}>
          Experienced traders can go to our&nbsp;
          <a
            href="https://copy.tiger.trade/traders/index-en.html"
            target="_blank"
            className={style.link}
          >
            Traders' site
          </a>
          &nbsp; to see if they qualify to join our pool of experts.
        </div>
      </>
    ),
  },
  */
  {
    id: 4,
    title: 'Are tiger.com products free to use?',
    text: (
      <>
        <a href="https://www.tiger.trade/terminal" className={style.link}>
          Tiger.Trade trading platform
        </a>
        &nbsp; is free to download and comes with an unlimited free Crypto license. You can trade
        cryptocurrency assets on top global cryptocurrency exchanges completely free of charge.
        <div className={style.topOffset}>
          If you’re interested in other types of assets, such as stocks, futures, options and Forex
          market, you’ll need to purchase the Full license. You’ll be pleased to learn that
          Tiger.Trade is one of the most affordable professional trading platforms on the market.
          Learn more about&nbsp;
          <a href="https://www.tiger.trade/terminal/prices" className={style.link}>
            our Pricing.
          </a>
        </div>
        {/*
        <div className={style.topOffset}>
          <a href="https://copy.tiger.trade/index-en.html" className={style.link}>
            Tiger.Trade Copy
          </a>
          &nbsp; is free to download. Users only need to fund their Wallet and delegate funds to
          traders.
        </div>
        */}
      </>
    ),
  },
  {
    id: 5,
    title: 'How to download tiger.com products?',
    text: (
      <>
        <ul>
          <li>
            To download <mark>Tiger.Trade</mark> trading platform for Windows,&nbsp;
            <a href="https://www.tiger.trade/terminal/download" className={style.linkSecondary}>
              follow this link
            </a>
            .
          </li>
          {/*
          <li>
            To download <mark>Tiger.Trade Copy</mark> app, go to&nbsp;
            <a
              href="https://apps.apple.com/us/app/tiger-trade-binance-copytrade/id1604212247"
              target="_blank"
              className={style.linkSecondary}
            >
              App Store
            </a>
            &nbsp; for iPhone version or&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=trade.tigr.copytrading"
              target="_blank"
              className={style.linkSecondary}
            >
              Google Play
            </a>{' '}
            for Android devices.
          </li>
          */}
          <li>
            To download <mark>Tiger.Trade for macOS</mark>,&nbsp;
            <a href={TERMINAL_MAC_OS_LINKS.en} target="_blank" className={style.linkSecondary}>
              follow this link
            </a>
            .
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    title: 'Which assets can you trade with tiger.com?',
    text: (
      <ul>
        <li>
          <a href="https://www.tiger.trade/terminal" className={style.link}>
            Tiger.Trade platform for Windows
          </a>
          &nbsp; provides traders with access to multiple types of assets within a single workspace.
          Users can buy and sell stocks, futures, options, cryptocurrencies and trade on Forex.
        </li>
        {/*
        <li>
          <a href="https://copy.tiger.trade/index-en.html" className={style.link}>
            Tiger.Trade copytrading app
          </a>
          &nbsp; allows subscribers to automatically copy futures trades on the Binance crypto
          exchange.
        </li>
        */}
        <li>
          <a href={TERMINAL_MAC_OS_LINKS.en} className={style.link}>
            Tiger.Trade for macOS
          </a>
          &nbsp; allows users to trade cryptocurrencies on popular exchanges.
        </li>
      </ul>
    ),
  },
  {
    id: 7,
    title: 'Is it safe to use tiger.com?',
    text: <>{t('drop_down_list_content7')}</>,
  },
];

const DROPDOWN_LIST_DATA_RU = (t: TFunction) => [
  {
    id: 1,
    title: 'Что такое tiger.com?',
    text: (
      <>
        С 2015 года tiger.com разрабатывает продукты для трейдеров любого уровня: от начинающих
        пользователей до профессионалов. Над всеми продуктами работает международная команда из
        разных точек мира, штаб-квартира компании находится в Швейцарии.
        <ul className={style.topOffset}>
          Наши продукты:
          <li>
            <a href="https://www.tiger.trade/ru/terminal" className={style.link}>
              Tiger.Trade Platform для Windows
            </a>
            &nbsp; — платформа для трейдинга на фондовых рынках и криптовалютных биржах.
          </li>
          <li>
            <a href={TERMINAL_MAC_OS_LINKS.ru} className={style.link}>
              Tiger.Trade macOS
            </a>{' '}
            — торговая платформа для macOS
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    title: 'Что такое торговая платформа?',
    text: (
      <>
        Tiger.Trade Platform — это торгово-аналитическая платформа, объединяющая все необходимые
        инструменты для успешного трейдинга. Платформа включает:
        <ul>
          <li>
            торговлю акциями, фьючерсами, опционами, криптовалютами и инструментами рынка Forex,
          </li>
          <li>торговлю в режиме «Демо» на исторических и реальных данных,</li>
          <li>встроенный в терминал риск-менеджер,</li>
          <li>
            многофункциональные графики, включая кластеры, ренко и объемные бары, а также множество
            индикаторов,
          </li>
          <li>стакан цен с обширным функционалом для активного трейдинга.</li>
        </ul>
        <div>На платформе можно подключаться к:</div>
        <ul>
          <li>
            различным криптовалютным и фондовым биржам, включая NYSE, NASDAQ, MOEX, SPBEX, Binance,
            OKX, Huobi и ByBit,
          </li>
          <li>
            торговым терминалам: SMARTcom, QUIK, MetaTrader 5, Rithmic, TRANSAQ и GAIN Capital
            (OEC).
          </li>
        </ul>
        Платформа Tiger.Trade доступна для&nbsp;
        <a href="https://www.tiger.trade/ru/terminal" className={style.link}>
          Windows
        </a>
        &nbsp;и&nbsp;
        <a href={TERMINAL_MAC_OS_LINKS.ru} className={style.link}>
          macOS
        </a>
        .
      </>
    ),
  },
  /*
  {
    id: 3,
    title: 'Что такое Tiger.Trade Copy?',
    text: (
      <>
        <a href="https://copy.tiger.trade/traders/index-ru.html" className={style.link}>
          Tiger.Trade Copy
        </a>
        &nbsp; - приложение для копитрейдинга, с которым пользователи автоматически копируют сделки
        успешных криптотрейдеров. Для этого достаточно подключить свой Binance-аккаунт, выбрать
        трейдера для копирования, и система начнёт повторять каждую транзакцию, совершаемую
        трейдером.
        <div className={style.topOffset}>
          Приложение Tiger.Trade Copy доступно для&nbsp;
          <a
            target="_blank"
            href="https://apps.apple.com/ru/app/tiger-trade-%D0%BA%D1%80%D0%B8%D0%BF%D1%82%D0%BE-%D1%81%D0%B8%D0%B3%D0%BD%D0%B0%D0%BB%D1%8B/id1604212247"
            className={style.link}
          >
            iOS
          </a>
          &nbsp; и&nbsp;
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=trade.tigr.copytrading&hl=en&gl=US"
            className={style.link}
          >
            Android
          </a>
          .
        </div>
        <div className={style.topOffset}>
          Если вы опытный криптотрейдер, проверьте себя на соответствие требованиям Tiger.Trade:
          возможно, вы станете тем, кого будут копировать наши пользователи.
        </div>
      </>
    ),
  },
  */
  {
    id: 4,
    title: 'Сколько стоят продукты tiger.com?',
    text: (
      <>
        Это зависит от конкретного инструмента и продукта:
        <ul className={style.topOffset}>
          <li>
            У&nbsp;
            <a href="https://www.tiger.trade/ru/terminal" className={style.link}>
              платформы Tiger.Trade
            </a>
            &nbsp; есть несколько видов лицензий. Всем пользователям доступна полностью бесплатная
            Криптолицензия для торговли на криптобиржах. Если вас интересует торговля акциями,
            фьючерсами, опционами и инструментами рынка Forex, для этого есть Полная лицензия. Для
            тестирования стратегий есть лицензия History Player. Все лицензии можно посмотреть в
            разделе с ценами.
            <a href="https://www.tiger.trade/ru/terminal/prices" className={style.link} />
          </li>
          {/*
          <li className={style.topOffset}>
            Приложение&nbsp;
            <a href="https://copy.tiger.trade/index-ru.html" className={style.link}>
              для копитрейдинга Tiger.Trade Copy
            </a>
            &nbsp;можно скачать бесплатно, нужно только выделить средства на копирование трейдеров и
            их сделок.
          </li>
          */}
        </ul>
      </>
    ),
  },
  {
    id: 5,
    title: 'Как скачать продукты tiger.com?',
    text: (
      <>
        Способ загрузки зависит от нужного вам продукта:
        <ul className={style.topOffset}>
          <li>
            Скачать платформу <mark>Tiger.Trade</mark> для Windows можно&nbsp;
            <a href="https://www.tiger.trade/ru/terminal" className={style.linkSecondary}>
              по ссылке
            </a>
            .
          </li>
          {/*
          <li>
            Приложение для копитрейдинга <mark>Tiger.Trade Copy</mark>&nbsp; можно скачать для&nbsp;
            <a
              href="https://apps.apple.com/us/app/tiger-trade-binance-copytrade/id1604212247"
              target="_blank"
              className={style.linkSecondary}
            >
              IOS
            </a>
            &nbsp; или для&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=trade.tigr.copytrading"
              target="_blank"
              className={style.linkSecondary}
            >
              Android
            </a>
            ;
          </li>
          */}
          <li>
            Скачать <mark>Терминал для macOS</mark> можно&nbsp;
            <a href={TERMINAL_MAC_OS_LINKS.ru} className={style.linkSecondary}>
              по ссылке
            </a>
            .
          </li>
          <div className={style.topOffset}>
            Мы не рекомендуем скачивать наши инструменты на сторонних сайтах, поскольку в этом
            случае не можем гарантировать безопасность скаченного ПО.
          </div>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    title: 'С какими активами можно работать через tiger.com?',
    text: (
      <>
        Это зависит от выбранного продукта:
        <ul className={style.topOffset}>
          <li>
            С&nbsp;
            <a href="https://www.tiger.trade/ru/terminal" className={style.link}>
              платформой Tiger.Trade для Windows
            </a>
            &nbsp; можно из окна одного терминала торговать акциями, фьючерсами, криптовалютами,
            опционами и инструментами рынка Forex;
          </li>
          {/*
          <li>
            В&nbsp;
            <a href="https://copy.tiger.trade/index-ru.html" className={style.link}>
              приложении для копитрейдинга Tiger.Trade Copy
            </a>
            &nbsp; можно копировать фьючерсные сделки других трейдеров на Binance;
          </li>
           */}
          <li>
            В&nbsp;
            <a href={TERMINAL_MAC_OS_LINKS.ru} className={style.link}>
              macOS-терминале
            </a>
            &nbsp; возможно торговать криптовалютами.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 7,
    title: 'Насколько безопасно использовать tiger.com?',
    text: <>{t('drop_down_list_content7')}</>,
  },
];

const DROPDOWN_LIST_DATA_UA = (t: TFunction) => [
  {
    id: 1,
    title: 'Що таке tiger.com?',
    text: (
      <>
        З 2015 року tiger.com займається розробкою фінтех продуктів для трейдерів будь-якого рівня:
        від досвідчених професіоналів до звичайних користувачів. Над нашими продуктами працює
        міжнародна команда ентузіастів у галузі фінтех. Штаб-квартира компанії знаходиться у
        Швейцарії.
        <ul className={style.topOffset}>
          Наші продукти:
          <li>
            <a href="https://www.tiger.trade/ua/terminal" className={style.link}>
              Tiger.Trade for Windows
            </a>
            &nbsp; — комп'ютерна платформа, яка дозволяє вести торгівлю на фінансових ринках та
            криптовалютних біржах.
          </li>
          <li>
            <a href={TERMINAL_MAC_OS_LINKS.ru} className={style.link}>
              Tiger.Trade macOS
            </a>{' '}
            — торговий додаток для macOS
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    title: 'Що таке торгова платформа?',
    text: (
      <>
        Торгова платформа Tiger.Trade – це гнучке рішення для торгівлі на фондових, ф’ючерсних,
        криптовалютних ринках та Форекс. Платформа має широкий арсенал інструментів, що здатні
        задовольнити як новачків, так і професіональних трейдерів. Платформа Tiger.Trade пропонує:
        <ul className={style.topOffset}>
          <li>торгівлю акціями, ф’ючерсами, опціонами, криптовалютою та інструментами Форекс</li>
          <li>демо-торгівлю на живих та історичних даних</li>
          <li>вбудовану систему керування ризиками</li>
          <li>
            багатофункціональні діаграми, включаючи кластери, Renko, об’ємні бари та різноманітні
            індикатори
          </li>
          <li>стакан цін з широким функціоналом для активної торгівлі.</li>
        </ul>
        <div>Завдяки платформі можна підключатися до:</div>
        <ul className={style.topOffset}>
          <li>
            різних криптовалютних та фондових бірж, таких як NYSE, NASDAQ, MOEX, SPBEX, Binance,
            OKX, Huobi та ByBit,
          </li>
          <li>
            торгових терміналів: SMARTcom, QUIK, MetaTrader 5, Rithmic, TRANSAQ та GAIN Capital
            (OEC).
          </li>
        </ul>
        Наразі платформа Tiger.Trade доступна для&nbsp;
        <a href="https://www.tiger.trade/ua/terminal" target="_blank" className={style.link}>
          Microsoft Windows
        </a>
        &nbsp;та&nbsp;
        <a href={TERMINAL_MAC_OS_LINKS.en} target="_blank" className={style.link}>
          macOS
        </a>
        .
      </>
    ),
  },
  /*
  {
    id: 3,
    title: 'Що таке Tiger.Trade Copy?',
    text: (
      <>
        <a href="https://copy.tiger.trade/index-en.html" target="_blank" className={style.link}>
          Tiger.Trade Copy
        </a>
        &nbsp; – це мобільний додаток для копітрейдингу, що дозволяє копіювати операції професійних
        трейдерів. Користувачеві потрібно лише створити рахунок, підключитися та поповнити гаманець.
        Щойно користувач підпишеться на трейдера, додаток почне автоматично копіювати кожну його
        операцію.
        <div className={style.topOffset}>
          Tiger.Trade Copy доступний для{' '}
          <a
            href="https://apps.apple.com/us/app/tiger-trade-binance-copytrade/id1604212247"
            target="_blank"
            className={style.link}
          >
            iOS
          </a>
          &nbsp; та&nbsp;
          <a
            href="https://play.google.com/store/apps/details?id=trade.tigr.copytrading"
            target="_blank"
            className={style.link}
          >
            Android
          </a>
          .
        </div>
      </>
    ),
  },
  */
  {
    id: 4,
    title: 'Продукти tiger.com безоплатні?',
    text: (
      <>
        <a href="http://tiger.trade/ua/terminal" className={style.link}>
          Торгову платформу Tiger.Trade
        </a>
        &nbsp; можна завантажити та використовувати безоплатно з необмеженою криптоліцензією. Ви
        зможете торгувати криптовалютою на провідних міжнародних криптобіржах абсолютно безкоштовно.
        <div className={style.topOffset}>
          Якщо ж вас цікавлять інші види активів, такі як акції, ф’ючерси, опціони та інструменти
          Форекс, доведеться придбати повну ліцензію. На щастя, Tiger.Trade є однією з
          найдоступніших професійних торгових платформ на ринку. Дізнайтеся більше про&nbsp;
          <a href="https://www.tiger.trade/ua/terminal/prices" className={style.link}>
            наші ціни
          </a>
          .
        </div>
        {/*
        <div className={style.topOffset}>
          <a href="https://copy.tiger.trade/index-en.html" className={style.link}>
            Tiger.Trade Copy
          </a>
          &nbsp; можна завантажити безоплатно. Користувачеві потрібно лише поповнити гаманець та
          довірити кошти трейдерам.
        </div>
        */}
      </>
    ),
  },
  {
    id: 5,
    title: 'Як завантажити продукти tiger.com?',
    text: (
      <>
        <ul>
          <li>
            Щоб завантажити торгову платформу <mark>Tiger.Trade</mark> для Windows,&nbsp;
            <a href="https://www.tiger.trade/ua/terminal/download" className={style.linkSecondary}>
              перейдіть по посиланню
            </a>
            .
          </li>
          {/*
          <li>
            Щоб завантажити додаток <mark>Tiger.Trade Copy</mark> для iPhone, перейдіть до&nbsp;
            <a
              href="https://apps.apple.com/us/app/tiger-trade-binance-copytrade/id1604212247"
              target="_blank"
              className={style.linkSecondary}
            >
              App&nbsp;Store
            </a>
            .&nbsp; Версія для Android доступна на&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=trade.tigr.copytrading"
              target="_blank"
              className={style.linkSecondary}
            >
              Google Play
            </a>
            .
          </li>
           */}
          <li>
            Щоб завантажити термінал <mark>Tiger.Trade для macOS</mark>,&nbsp;
            <a href={TERMINAL_MAC_OS_LINKS.ua} target="_blank" className={style.linkSecondary}>
              перейдіть по посиланню
            </a>
            .
          </li>
        </ul>
        <div className={style.topOffset}>
          Ми не рекомендуємо завантажувати та встановлювати продукти Tiger.Trade зі сторонніх
          веб-сайтів, бо не можемо гарантувати, що вони є безпечними.
        </div>
      </>
    ),
  },
  {
    id: 6,
    title: 'З якими активами можна працювати через tiger.com?',
    text: (
      <ul>
        <li>
          <a href="https://www.tiger.trade/ua/terminal" className={style.link}>
            Платформа Tiger.Trade для Windows
          </a>
          &nbsp; надає трейдерам доступ до багатьох типів активів у єдиному рішенні. Ви можете
          купляти та продавати акції, ф’ючерси, опціони, криптовалюти, або торгувати на ринках
          Форекс.
        </li>
        {/*
        <li>
          <a href="https://copy.tiger.trade/index-en.html" className={style.link}>
            Додаток для копітрейдингу Tiger.Trade
          </a>
          &nbsp; дозволяє підписникам автоматично копіювати торгівлю ф’ючерсами на криптобіржі
          Binance.
        </li>
         */}
        <li>
          <a href={TERMINAL_MAC_OS_LINKS.ua} className={style.link}>
            Tiger.Trade для macOS
          </a>
          &nbsp; надає користувачам можливість торгувати криптовалютами на популярних біржах.
        </li>
      </ul>
    ),
  },
  {
    id: 7,
    title: <div>Чи безпечно користуватися tiger.com?</div>,
    text: <>{t('drop_down_list_content7')}</>,
  },
];

const DROPDOWN_LIST_DATA_ES = (t: TFunction) => [
  {
    id: 1,
    title: '¿Qué es tiger.com?',
    text: (
      <>
        Desde 2015, tiger.com desarrolla productos fintech para traders de todo tipo: desde
        profesionales muy experimentados hasta usuarios casuales. Nuestras soluciones son
        desarrolladas por un equipo internacional de entusiastas fintech. La empresa tiene sede en
        Suiza.
        <ul className={style.topOffset}>
          Nuestros productos son:
          <li>
            <a href="https://www.tiger.trade/en/terminal" className={style.link}>
              Tiger.Trade para Windows
            </a>
            &nbsp; — una plataforma de escritorio que permite operar en mercados financieros y de
            criptomonedas.
          </li>
          <li>
            <a href={TERMINAL_MAC_OS_LINKS.ru} className={style.link}>
              Tiger.Trade macOS
            </a>{' '}
            — Nuestra aplicación de trading para macOS
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    title: '¿Qué es la plataforma de trading?',
    text: (
      <>
        La plataforma de trading Tiger.Trade es un área de trabajo de escritorio personalizable para
        operar con acciones, futuros, criptomonedas y activos de divisas. Ofrece una exhaustiva gama
        de herramientas para satisfacer las necesidades de principiantes, traders avanzados y
        profesionales por igual. Características de la plataforma Tiger.Trade:
        <ul className={style.topOffset}>
          <li>trading de acciones, futuros, opciones, cripto y activos de divisas</li>
          <li>demos de trading con datos históricos y en tiempo real</li>
          <li>sistema de gestión de riesgos</li>
          <li>
            gráficos multifunción, incluyendo clústeres, Renko y barras de volumen, con varios
            indicadores
          </li>
          <li>libro de órdenes (DOM) con varias funciones para el trading activo.</li>
        </ul>
        <div>Al usar la plataforma, los usuarios pueden conectarse a:</div>
        <ul className={style.topOffset}>
          <li>
            diferentes bolsas de valores y criptomonedas como NYSE, NASDAQ, MOEX, SPBEX, Binance,
            OKX, Huobi, y ByBit
          </li>
          <li>
            terminales de trading: SMARTcom, QUIK, MetaTrader 5, Rithmic, TRANSAQ, y GAIN Capital
            (OEC).
          </li>
        </ul>
        Tiger.Trade está disponible para&nbsp;
        <a href="https://www.tiger.trade/en/terminal" target="_blank" className={style.link}>
          Microsoft Windows
        </a>
        &nbsp;y&nbsp;
        <a href={TERMINAL_MAC_OS_LINKS.es} target="_blank" className={style.link}>
          macOS
        </a>
        .
      </>
    ),
  },
  /*
  {
    id: 3,
    title: '¿Qué es Tiger.Trade Copy?',
    text: (
      <>
        <a href="https://copy.tiger.trade/index-en.html" target="_blank" className={style.link}>
          Tiger.Trade Copy
        </a>
        &nbsp; – es una app móvil de copiar operaciones que permite copiar a traders experimentados
        y ganar con sus operaciones de criptomonedas. Los usuarios simplemente crean una cuenta, se
        conectan y agregan fondos a la billetera. Una vez que se suscriben a un trader, la
        aplicación copiará automáticamente todas las operaciones que realice.
        <div className={style.topOffset}>
          Tiger.Trade Copy está disponible para{' '}
          <a
            href="https://apps.apple.com/us/app/tiger-trade-binance-copytrade/id1604212247"
            target="_blank"
            className={style.link}
          >
            iOS
          </a>
          &nbsp; та&nbsp;
          <a
            href="https://play.google.com/store/apps/details?id=trade.tigr.copytrading"
            target="_blank"
            className={style.link}
          >
            Android
          </a>
          .
        </div>
        <div className={style.topOffset}>
          Los traders con experiencia pueden visitar el Sitio de traders para ver si cumplen los
          requisitos de nuestro grupo de expertos.
        </div>
      </>
    ),
  },
  */
  {
    id: 4,
    title: '¿Los productos tiger.com son gratis?',
    text: (
      <>
        -{' '}
        <a href="http://tiger.trade/en/terminal" className={style.link}>
          La plataforma de trading Tiger.Trade
        </a>
        &nbsp; es gratuita y viene con una licencia cripto ilimitada y gratuita. Puede operar con
        activos cripto en las principales plataformas de criptomonedas del mundo de forma gratuita.
        <div className={style.topOffset}>
          Si está interesado en otros tipos de activos, como acciones, futuros, opciones y mercado
          de divisas, tendrá que comprar la licencia completa. Tiger.Trade es una de las plataformas
          de trading profesional más asequibles del mercado. Aprenda más sobre&nbsp;
          <a href="https://www.tiger.trade/en/terminal/prices" className={style.link}>
            nuestros precios
          </a>
          .
        </div>
        {/*
        <div className={style.topOffset}>
          -{' '}
          <a href="https://copy.tiger.trade/index-en.html" className={style.link}>
            Tiger.Trade Copy
          </a>
          &nbsp; es de descarga gratuita. Solo debe agregar fondos a su billetera y delegar fondos a
          traders.
        </div>
        */}
      </>
    ),
  },
  {
    id: 5,
    title: '¿Cómo descargar los productos tiger.com?',
    text: (
      <>
        <ul>
          <li>
            Para descargar la plataforma de trading <mark>Tiger.Trade</mark> para Windows, acceda
            a&nbsp;
            <a href="https://www.tiger.trade/terminal/download" className={style.linkSecondary}>
              este&nbsp;enlace
            </a>
            .
          </li>
          {/*
          <li>
            - Para descargar la aplicación <mark>Tiger.Trade Copy</mark> para iPhone, visite&nbsp;
            <a
              href="https://apps.apple.com/us/app/tiger-trade-binance-copytrade/id1604212247"
              target="_blank"
              className={style.linkSecondary}
            >
              App Store
            </a>
            &nbsp; o visite&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=trade.tigr.copytrading"
              target="_blank"
              className={style.linkSecondary}
            >
              Google Play
            </a>{' '}
            para Android.
          </li>
           */}
          <li>
            Para descargar <mark>Tiger.Trade para macOS</mark>, acceda a&nbsp;
            <a href={TERMINAL_MAC_OS_LINKS.en} target="_blank" className={style.linkSecondary}>
              este enlace
            </a>
            .
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    title: '¿Qué activos puede comerciar en tiger.com?',
    text: (
      <ul>
        <li>
          - La plataforma{' '}
          <a href="https://www.tiger.trade/terminal" className={style.link}>
            Tiger.Trade para Windows
          </a>
          &nbsp; permite que los traders accedan a varios activos desde un único lugar. Pueden
          comprar y vender acciones, futuros, opciones, criptomonedas y operar en mercados de
          divisas.
        </li>
        {/*
        <li>
          - La aplicación{' '}
          <a href="https://copy.tiger.trade/index-en.html" className={style.link}>
            Tiger.Trade Copytrading
          </a>
          &nbsp; permite que los suscriptores copien automáticamente las operaciones de futuros en
          la plataforma cripto de Binance.
        </li>
         */}
        <li>
          -{' '}
          <a href={TERMINAL_MAC_OS_LINKS.en} className={style.link}>
            Tiger.Trade para macOS
          </a>
          &nbsp; permite operar con criptomonedas en plataformas populares.
        </li>
      </ul>
    ),
  },
  {
    id: 7,
    title: '¿Es seguro usar tiger.com?',
    text: <>{t('drop_down_list_content7')}</>,
  },
];

const LOCALE_TO_LIST = {
  en: DROPDOWN_LIST_DATA_EN,
  ua: DROPDOWN_LIST_DATA_UA,
  ru: DROPDOWN_LIST_DATA_RU,
  es: DROPDOWN_LIST_DATA_ES,
};

const FAQ = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  return (
    <div className={style.main}>
      <div className={style.faq}>
        <div className={style.mainContainer}>
          <div className={style.title}>{t('faq_h1')}</div>
          <div className={style.wrap}>
            {LOCALE_TO_LIST[locale](t).map(({ id, text, title }) => (
              <FaqSection key={id} text={text} title={title} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

type FaqSectionProps = {
  title: string;
  text: string;
};

const FaqSection: FC<FaqSectionProps> = ({ text, title }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = (): void => {
    setIsOpen((value) => !value);
  };

  return (
    <div className={clsx(style.el, isOpen && style.openedEl)}>
      <div className={style.elTitle} onClick={toggleDropdown}>
        {title}
        <div className={style.arrow} />
      </div>
      <div className={style.in}>
        <div className={style.ind}>{text}</div>
      </div>
    </div>
  );
};

export default FAQ;
