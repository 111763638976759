import style from './style.module.scss';
import BinanceIcon from '../../../public/images/partners/binancedex.svg';
import FideIcon from '../../../public/images/partners/fide.svg';
import Binance2Icon from '../../../public/images/partners/binance.svg';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  PARTNERS_GAME_FI_LINKS,
  PARTNERS_FIDE_LINKS,
  PARTNERS_PARTNERSHIP_LINKS,
} from '@/core/constants';

const Partners = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  return (
    <div className={style.main}>
      <div className={style.partners}>
        <div className={style.mainContainer}>
          <div className={style.title}>
            {t('slide5_h1')}
            <br />
            <span dangerouslySetInnerHTML={{ __html: t('slide5_h1_1') }} />
          </div>

          <div dangerouslySetInnerHTML={{ __html: t('slide5_h2') }} className={style.subTitle} />
          <div className={style.grid}>
            <a href={PARTNERS_GAME_FI_LINKS[locale] || ''} target="_blank" className={style.el}>
              <div className={style.top}>
                <div className={style.pic}>
                  <BinanceIcon />
                </div>
                <div className={style.label}>{t('slide5_infoblock1_h1')}</div>
                <div className={style.text}>{t('slide5_infoblock1_h2')}</div>
              </div>
              <div className={style.btn}>
                <span>{t('slide5_infoblock1_hoverbutton')}</span>
              </div>
            </a>

            <a href={PARTNERS_FIDE_LINKS[locale] || ''} target="_blank" className={style.el}>
              <div className={style.top}>
                <div className={style.pic}>
                  <FideIcon />
                </div>
                <div className={style.label}>{t('slide5_infoblock2_h1')}</div>
                <div className={style.text}>{t('slide5_infoblock2_h2')}</div>
              </div>
              <div className={style.btn}>
                <span>{t('slide5_infoblock2_hoverbutton')}</span>
              </div>
            </a>

            <a href={PARTNERS_PARTNERSHIP_LINKS[locale] || ''} target="_blank" className={style.el}>
              <div className={style.top}>
                <div className={style.pic}>
                  <Binance2Icon />
                </div>
                <div className={style.label}>{t('slide5_infoblock3_h1')}</div>
                <div className={style.text}>{t('slide5_infoblock3_h2')}</div>
              </div>
              <div className={style.btn}>
                <span>{t('slide5_infoblock3_hoverbutton')}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
