import style from './style.module.scss';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className={style.main}>
      <div className={style.contact}>
        <div className={style.mainContainer}>
          <div className={style.title}>{t('slide6_h1')}</div>
          <Link href={'/contacts'}>
            <div className={style.btnGrad}>
              <span>{t('slide6_button')}</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contact;
