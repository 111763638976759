export const Icon3 = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="8" fill="url(#paint0_linear_21567_5995)" />
      <mask
        id="mask0_21567_5995"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" rx="8" fill="#D99BFF" />
      </mask>
      <g mask="url(#mask0_21567_5995)">
        <path
          d="M17.9429 -6.97177L11.2665 6.46051C10.2832 8.43876 7.88245 9.24535 5.9042 8.26209L-9.31905 0.695536L-0.86232 -16.3187L17.9429 -6.97177Z"
          stroke="#0F0D19"
          strokeWidth="4"
        />
        <path
          d="M31.9865 26.4945L20.8088 16.4915C19.1626 15.0183 16.6338 15.1586 15.1606 16.8048L3.8239 29.4728L17.9823 42.1432L31.9865 26.4945Z"
          stroke="#0F0D19"
          strokeWidth="4"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_21567_5995"
          x1="12.8571"
          y1="24"
          x2="12.8571"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0C5DFD" />
          <stop offset="1" stopColor="#00A5F8" />
        </linearGradient>
      </defs>
    </svg>
  );
};
