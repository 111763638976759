export const Icon2 = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="8" fill="url(#paint0_linear_16376_45716)" />
      <mask id="mask0_16376_45716" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" rx="8" fill="#D99BFF" />
      </mask>
      <g mask="url(#mask0_16376_45716)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-2.5 1L12.2224 9.5C15.0922 11.1569 16.0754 14.8264 14.4186 17.6962L4.91858 34.1506L-15 22.6506L-2.5 1Z"
          fill="#0F0D19"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.03613 6.46484L9.35617 12.4648C10.7911 13.2933 11.2827 15.128 10.4542 16.5629L3.45425 28.6873L-9.53613 21.1873L-1.03613 6.46484Z"
          fill="#9A3EFD"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_16376_45716"
          x1="-1.5"
          y1="16.5"
          x2="24"
          y2="1.11759e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E3AFE" />
          <stop offset="1" stopColor="#CC36FB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
