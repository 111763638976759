import style from './style.module.scss';
import classNames from 'classnames';
import SwissIcon from './../../../public/images/top-block/swiss.svg';
import { Icon3 } from '../../../public/images/top-block/Icon3';
import { Icon2 } from '../../../public/images/top-block/Icon2';
import { Icon1 } from '../../../public/images/top-block/Icon1';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const Main = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  return (
    <div className={style.main}>
      <div className={style.topBlock}>
        <div className={style.mainContainer}>
          <div className={style.content}>
            <div className={style.swiss}>
              <SwissIcon />
              {t('slide1_swiss_mark')}
            </div>
            <h1 dangerouslySetInnerHTML={{ __html: t('slide1_h1') }} className={style.title} />
            <div
              dangerouslySetInnerHTML={{ __html: t('slide1_h2') }}
              className={classNames(style.subTitle, { [style.subTitleEn]: locale === 'en' })}
            />
            <a href="#products" className={style.btnGrad}>
              <span>{t('slide1_our_products_button')}</span>
            </a>
          </div>
          <div className={style.bottom}>
            <div className={style.item}>
              <Icon1 />
              {t('slide1_info1')}
            </div>
            <div className={style.item}>
              <Icon2 />
              {t('slide1_info2')}
            </div>
            <div className={style.item}>
              <Icon3 />
              {t('slide1_info3')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
