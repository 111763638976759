export const MacOS = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19436_9210)">
        <path
          d="M9.576 0.5H2.424C1.0845 0.5 0 1.5845 0 2.924V10.0775C0 11.4155 1.0845 12.5 2.424 12.5H9.5775C10.9155 12.5 12.0015 11.4155 12.0015 10.076V2.924C12 1.5845 10.9155 0.5 9.576 0.5Z"
          fill="url(#paint0_linear_19436_9210)"
        />
        <path
          d="M5.94863 3.2579L6.19163 2.8379C6.34163 2.5754 6.67613 2.4869 6.93863 2.6369C7.20113 2.7869 7.28963 3.1214 7.13963 3.3839L4.79813 7.4369H6.49163C7.04063 7.4369 7.34813 8.0819 7.10963 8.5289H2.14463C1.84163 8.5289 1.59863 8.2859 1.59863 7.9829C1.59863 7.6799 1.84163 7.4369 2.14463 7.4369H3.53663L5.31863 4.3484L4.76213 3.3824C4.61213 3.1199 4.70063 2.7884 4.96313 2.6354C5.22563 2.4854 5.55713 2.5739 5.71013 2.8364L5.94863 3.2579ZM3.84263 9.0914L3.31763 10.0019C3.16763 10.2644 2.83313 10.3529 2.57063 10.2029C2.30813 10.0529 2.21963 9.7184 2.36963 9.4559L2.75963 8.7809C3.20063 8.6444 3.55913 8.7494 3.84263 9.0914ZM8.36363 7.4399H9.78413C10.0871 7.4399 10.3301 7.6829 10.3301 7.9859C10.3301 8.2889 10.0871 8.5319 9.78413 8.5319H8.99513L9.52763 9.4559C9.67763 9.7184 9.58913 10.0499 9.32663 10.2029C9.06413 10.3529 8.73263 10.2644 8.57963 10.0019C7.68263 8.4464 7.00913 7.2824 6.56213 6.5069C6.10463 5.7179 6.43163 4.9259 6.75413 4.6574C7.11263 5.2724 7.64813 6.2009 8.36363 7.4399Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_19436_9210"
          x1="6.00075"
          y1="0.5"
          x2="6.00075"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18BFFB" />
          <stop offset="1" stopColor="#2072F3" />
        </linearGradient>
        <clipPath id="clip0_19436_9210">
          <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
