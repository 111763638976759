import { BROKER_LINKS, EXCHANGE_LINKS, TERMINAL_MAC_OS_LINKS } from '@/core/constants';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MacOS } from '@/public/images/products/MacOS';
import style from './style.module.scss';

const Products = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  return (
    <div id={'products'} className={style.main}>
      <div className={style.products}>
        <div className={style.mainContainer}>
          <span className={style.title}>{t('slide2_h1')}</span>
          <span className={style.subTitle}>{t('slide2_h2')}</span>
          <div className={style.grid}>
            <div className={clsx(style.el, style.bg2)}>
              <div className={style.type}>
                <h2 className={style.typeItem}>{t('slide2_trading_platform_block')}</h2>
              </div>
              <div className={style.gridTitle}>
                <span className={style.grad}>{t('slide2_trading_platform_h1')}</span>
                <br />
                {t('slide2_trading_platform_h1_1')}
              </div>
              <div className={clsx(style.text, style.text2ver)}>
                {t('slide2_trading_platform_h2')}
              </div>
              <div className={style.links}>
                <Link href="/terminal">
                  <a>
                    <div className={clsx(style.btnWhite, style.btnOs)}>
                      <span className={style.btnPic}>
                        <svg width={11} height={11}>
                          <use xlinkHref="img/products/windows.svg#windows" />
                        </svg>
                      </span>
                      <span>{t('slide2_trading_platform_button1')}</span>
                    </div>
                  </a>
                </Link>
                <Link href={TERMINAL_MAC_OS_LINKS[locale]}>
                  <a>
                    <div className={clsx(style.btnWhite, style.btnMacOs)}>
                      <span className={clsx(style.btnPic, style.btnPicMacOS)}>
                        <MacOS />
                      </span>
                      <span>{t('slide2_trading_platform_button2')}</span>
                    </div>
                  </a>
                </Link>
              </div>
            </div>

            <div className={clsx(style.el, style.bg3)}>
              <div className={style.type}>
                <h2 className={style.typeItem}>{t('slide2_broker_block')}</h2>
              </div>
              <div className={style.gridTitle}>
                <span className={style.grad}>{t('slide2_broker_h1')}</span>
                <br />
                {t('slide2_broker_h1_1')}
              </div>
              <div
                className={style.text}
                dangerouslySetInnerHTML={{ __html: t('slide2_broker_h2') }}
              />
              <div className={style.links}>
                <Link href={BROKER_LINKS[locale]}>
                  <a>
                    <div className={clsx(style.btnWhite, style.btnGrad)}>
                      <span>{t('slide2_broker_button')}</span>
                    </div>
                  </a>
                </Link>
              </div>
            </div>

            <div className={clsx(style.el, style.bg4)}>
              <div className={style.type}>
                <h2 className={style.typeItem}>{t('slide2_exchange_block')}</h2>
              </div>
              <div className={style.gridTitle}>
                <span className={style.grad}>{t('slide2_exchange_h1')}</span>
                <br />
                {t('slide2_exchange_h1_1')}
              </div>
              <div
                className={style.text}
                dangerouslySetInnerHTML={{ __html: t('slide2_exchange_h2') }}
              />
              <div className={style.links}>
                <Link href={EXCHANGE_LINKS[locale]}>
                  <a>
                    <div className={clsx(style.btnWhite, style.btnGrad)}>
                      <span>{t('slide2_exchange_button')}</span>
                    </div>
                  </a>
                </Link>
              </div>
            </div>

            {/*
            <div className={clsx(style.el, style.bg1)}>
              <div className={style.type}>
                <h2 className={style.typeItem}>{t('slide2_copytrading_block')}</h2>
              </div>
              <div className={style.gridTitle}>
                <span className={style.grad}>{t('slide2_copytrading_h1')}</span>
                <br />
                {t('slide2_copytrading_h1_1')}
              </div>
              <div
                className={style.text}
                dangerouslySetInnerHTML={{ __html: t('slide2_copytrading_h2') }}
              />
              <div className={style.links}>
                <Link href={COPYTRADING_LINKS[locale]}>
                  <a>
                    <div className={clsx(style.btnWhite, style.btnGrad)}>
                      <span>{t('slide2_copytrading_button')}</span>
                    </div>
                  </a>
                </Link>
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
